@tailwind base;
@tailwind components;
@tailwind utilities;

a:hover{
    color: #e00000;
}

body{
    line-height: 1.4;
    font-size: 14px;
    color: #252a2b;
    overflow-x: hidden;
    background: white;
    position:initial !important;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

.swiper-wrapper{
    height: fit-content !important;
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

